/*
 * @description: 信息处理类
 * @Author: Jay
 * @Date: 2023-07-27 13:44:17
 * @LastEditors: Jay
 * @LastEditTime: 2023-09-08 11:44:44
 */
//导入 Axios 请求
import { createAxios } from '@/utils/request'
const request = createAxios();

//其他配置
// import operate from '@/utils/operate';

// 机构基本资料
export const information = (data?: any) =>
    request.post("/institution/index/information", data);

// 机构基本资料修改
export const editInformation = (data?: any) =>
    request.post("/institution/index/edit_information", data);

// 顾问列表
export const adviserList = (data?: any) =>
    request.post("/institution/anli/adviserList", data);

// 顾问添加
export const addAdviser = (data?: any) =>
    request.post("/institution/anli/addAdviser", data);

// 顾问详情
export const adviserDetai = (data?: any) =>
    request.post("/institution/anli/adviserDetai", data);

// 顾问删除
export const adviserDel = (data?: any) =>
    request.post("/institution/anli/adviserDel", data);

// 顾问修改
export const AdviserEdit = (data?: any) =>
    request.post("/institution/anli/AdviserEdit", data);

// 个人顾问履历添加修改
export const resumeReq = (data?: any) =>
    request.post("/institution/anli/resume", data);

// 顾问履历
export const adviserresume = (data?: any) =>
    request.post("/institution/anli/adviserresume", data);

// 案例列表
export const AnliList = (data?: any) =>
    request.post("/institution/anli/AnliList", data);

// 案例添加
export const Anliadd = (data?: any) =>
    request.post("/institution/anli/Anliadd", data);

// 案例修改
export const Anliedit = (data?: any) =>
    request.post("/institution/anli/Anliedit", data);

// 案例删除
export const Anlidel = (data?: any) =>
    request.post("/institution/anli/Anlidel", data);

// 案例详情
export const Anlidetai = (data?: any) =>
    request.post("/institution/anli/Anlidetai", data);

// 推荐学生
export const recommendStudent = (data?: any) =>
    request.post("/institution/Basic/recommend_student", data);

// 缴费状态
export const GetInorder = (data?: any) =>
    request.post("/institution/user/GetInorder", data);

// 救援订单
export const helpOrder = (data?: any) =>
    request.post("/institution/user/helpOrder", data);