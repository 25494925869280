/*
 * @description: 
 * @Author: Jay
 * @Date: 2023-07-22 10:08:52
 * @LastEditors: Jay
 * @LastEditTime: 2023-07-24 13:51:57
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
const app = createApp(App)

// element-ui 引入
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// 引入中文包
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
app.use(ElementPlus, {
    locale: zhCn,
})

// 顶部页头
import TopHeader from "./components/TopHeader/TopHeader.vue";
app.component('TopHeader', TopHeader)


//导入 主题配色
// import "./styles/theme/index.css"

// 全局方法
// import operate from "./utils/operate"
// app.config.globalProperties.$operate = operate;

app.use(store).use(router).mount('#app')
