/*
 * @description: 
 * @Author: Jay
 * @Date: 2023-07-22 10:11:26
 * @LastEditors: Jay
 * @LastEditTime: 2023-08-19 11:48:34
 */
import { createStore } from 'vuex'

export default createStore({
  state: {
    // 存储token 没登录默认false
    Authorization: localStorage.getItem('Authorization') ? localStorage.getItem('Authorization') : '',
    // 菜单是否收缩
    isCollapse: false
  },
  getters: {},
  mutations: {
    LOGIN(state, provider) {
      // 存储token
      state.Authorization = provider;
      localStorage.setItem('Authorization', provider);
      // console.log('存储token', state.Authorization)
    },
    LOGOUT(state) {
      state.Authorization = '';
      // 删除指定key本地存储的值
      localStorage.removeItem('Authorization')
      //菜单缓存
      sessionStorage.removeItem('menuId')
    },
  },
  actions: {
  },
  modules: {
  }
})