/*
 * @description: 
 * @Author: Jay
 * @Date: 2023-07-13 11:14:32
 * @LastEditors: Jay
 * @LastEditTime: 2023-07-27 13:52:07
 */
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

// 导入 路由
import { main } from "./main";

const routes: Array<RouteRecordRaw> = [
  // 登录
  {
    path: "/",
    name: "logInView",
    component: () => import('../views/LogInView/LogInView.vue'),
    meta: {
      title: "登录",
      //是否需要登录
      requireAuth: false
    }
  }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  //整合路由
  routes: [...routes, ...main],
})


/*
  前置 路由守卫
*/
/* eslint-disable */
router.beforeEach((to, from, next) => {
  /* -----通过本地缓存进行判断,获取指定key本地存储的值进行判断----- */
  if (to.meta.requireAuth) {
    // 获取指定key本地存储的值
    const token = localStorage.getItem('Authorization')
    if (token === null || token === '') {
      //登录弹窗
      console.log("路由守卫-去登录")
      router.push('/')
    } else {
      next()
    }
  } else {
    next()
  }
})
/* eslint-disable no-new */

/*
  后置 路由守卫
*/
router.afterEach((to: any) => {
  // console.log("后置 路由守卫", to, from)
  //更改每个页面的标题
  document.title = to.meta.title;
})

export default router
