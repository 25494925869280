/*
 * @description: 请求接口 配置
 * @Author: Jay
 * @Date: 2023-02-25 11:12:46
 * @LastEditors: Jay
 * @LastEditTime: 2023-08-17 13:28:39
 */

//导入 Axios 请求
import { createAxios } from '@/utils/request'
const request = createAxios();

//其他配置
// import operate from '@/utils/operate';

// 登陆
export const loginReq = (data?: any) =>
    request.post("/institution/index/login", data);

// 退出登陆
export const loginOut = (data?: any) =>
    request.post("/institution/index/login_out", data);

// 首页
export const homeIndex = (data?: any) =>
    request.post("/institution/Basic/index", data);

// 个人顾问标签获取
export const getLable = (data?: any) =>
    request.post("/institution/anli/getLable", data);

// 个人顾问服务类型获取
export const getadvType = (data?: any) =>
    request.post("/institution/anli/getadvType", data);

// 案例来源
export const lyuan = (data?: any) =>
    request.post("/institution/anli/lyuan", data);

// 机构关联用户（机构推荐的用户）
export const association = (data?: any) =>
    request.post("/institution/anli/association", data);

//入账明细-服务顾问
export const Getadviser = (data?: any) =>
    request.post("/institution/user/Getadviser", data);

//入账明细- 来源获取
export const GetEntryType = (data?: any) =>
    request.post("/institution/user/GetEntryType", data);

//入账明细- 入账状态
export const GetStatus = (data?: any) =>
    request.post("/institution/user/GetStatus", data);

//银行卡绑定-银行号获取
export const seachbank = (data?: any) =>
    request.post("/institution/user/seachbank", data);

// get 请求
// export const exportGet = (params?: any): Promise<any> =>
//     request.get("/api/search/get/web", { params });

// // post 请求
// export const exportPost = (data?: any) =>
//     //请求 token 添加
//     // request.post("/export", Object.assign(data, { token: operate.isToken() }));
//     request.post("/export", data);


/*
*使用 方法
*引入
    import {
        名字
    } from "../api/api"
*生命周期中 请求
    名字({请求参数}).then((res) => {
        console.log(res)
    })
*/
