/*
 * @description: 用户相关
 * @Author: Jay
 * @Date: 2023-07-31 14:42:03
 * @LastEditors: Jay
 * @LastEditTime: 2023-07-31 17:45:51
 */
//导入 Axios 请求
import { createAxios } from '@/utils/request'
const request = createAxios();

//其他配置
// import operate from '@/utils/operate';

// 密码修改
export const edirPassword = (data?: any) =>
    request.post("/institution/user/edit_password", data);

// 入账明细
export const entryDetail = (data?: any) =>
    request.post("/institution/user/entry_detail", data);

// 绑定银行卡查看
export const bankList = (data?: any) =>
    request.post("/institution/user/bankList", data);

// 银行卡绑定/修改（一个机构只可以绑定一个）
export const bindBank = (data?: any) =>
    request.post("/institution/user/bind_bank", data);

// 申请提现
export const cash = (data?: any) =>
    request.post("/institution/user/cash", data);

// 提现列表-记录
export const cashList = (data?: any) =>
    request.post("/institution/user/cashList", data);